<template>
    <div class="category-card">
      <div v-if="goodList.length > 0">        
        <div class="card" v-for="list in goodList" :key="list.id">
          <img :src="list.img" alt="" width="75" height="75" @click="cardClick(list.id)">
          <div class="right-box">
            <div class="title"  @click="cardClick(list.id)">{{ list.title }}</div>
            <div class="type" @click="cardClick(list.id)">{{ list.type }}</div>
            <div class="price-row">
              <div class="price" @click="cardClick(list.id)">
                <span class="icon">￥</span>
                <span>{{ list.price }}</span>
              </div>
              <img src="../../assets/images/cart-o.png" alt="" height="22" width="24" @click="addToCart(list)">
            </div>
          </div>
        </div>
      </div>
      <div class="none" v-else>暂无商品</div>
    </div>
</template>

<script>
export default {
    name:'CategoryCard',
    methods:{
      addToCart(list){
        this.$emit('addToCart', list)
      },
      cardClick(id){
        this.$emit('cardClick', id)
      }
    },
    data(){
        return {
        }
    },
    props: {
      goodList:{
        type: Array,
        default: ()=>[]
      },
    }
  };
</script>

<style lang="scss" scoped>
  .category-card {
    margin: 0 30px;
    border-radius: 5px;
    height: calc(100vh - 195px - constant(safe-area-inset-bottom));
    height: calc(100vh - 195px - env(safe-area-inset-bottom));
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+, edge */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .none {
      margin-top: 10px;
      font-size: 14px;
      color: #969699;
    }
    .card {
      display: flex;
      align-items: center;
      padding: 15px 10px;
      background: #fff;
      border-bottom: 1px solid rgba(0,0,0,0.03);
      img {
  
      }
      .right-box {
        width: 100%;
        text-align: left;
        padding: 0 10px;
        .title {
          font-weight: 600;
          color: #222;
          display: -webkit-box;
          -webkit-box-orient: vertical;    
          -webkit-line-clamp: 1;    
          overflow: hidden;
        }
        .type {
          color: #969699;
          font-size: 12px;
          margin: 10px 0;
        }
        .price-row {
          display: flex;
          justify-content: space-between;
          .price {
            color: red;
            font-weight: 600;
            font-size: 18px;
            .icon {
              font-size: 12px;
            }
          }
          img {
  
          }
        }
      }
    }
  }
</style>
  