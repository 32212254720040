<template>
    <div class="category-box" id="category">
        <div class="search" v-if="$store.state.userAgent === 'Mobile'">
          <div class="fake-input" @click="goSearch">搜索你要找的商品</div>
          <img src="../../assets/images/search.png" alt="" width="17" height="17" class="searchImg">
        </div>
        <div class="first-category-row">
          <div class="first-category">
            <div v-for="(list) in firstCategoryLabelList" :key="list.id" @click="changeFirstCategoryLabel(list.id)">
              <div class="first-category-item" v-show="list.mini_img_url">
                <img :class="[list.isSelected ? 'img-active' : 'img']" :src="list.mini_img_url" alt="" width="45px" height="45px">
                <div :class="[list.isSelected ? 'label-active' : 'label']">{{ list.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <category-card class="category-card" :goodList="goodList" @addToCart="addToCart" @cardClick="goShopDescPage" v-show="!loading"/>
        <side-bar class="sidebar" :labelList="labelList" @changeLabel="changeLabel"/>
    </div>
</template>

<script>
import CategoryCard from '../../components/card/CategoryCard.vue'
import SideBar from '../../components/sidebar/index.vue'
const seo = require('../../../config/index')
import { queryAllFirstCategoryByShopId, queryAllSecondCategoryById, querOnSaleCategoryGoodsByUid, queryCartGoodsTotalAmount, createCart } from '@/api/api'
import { Toast } from 'vant'
// const seo = require('../../../config/index')
import { getopenIdByCode } from '@/utils/index';

export default {
  name: 'Category',
  components: {
    SideBar,
    CategoryCard,
  },
  created(){
    this.getData()
    getopenIdByCode()
  },
  activated(){
    document.title = seo.config.seoPage.categoryPage.title
    this.firstCategoryId = sessionStorage.getItem('firstCategoryId')
  },
  watch:{
    firstCategoryId () {
      this.getData()
    }
  },
  data:()=>{
    return {
      goodList:[],
      labelList:[],
      cartAmount: 0,
      firstCategoryLabelList:[],
      appName: seo.config.appName,
      shopName: seo.config.shopName,
      loading: false,
      selectFirstCategoryId: '',
      firstCategoryId: sessionStorage.getItem('firstCategoryId'),
      isLogin: localStorage.getItem('token') ? true : false,
    //   userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0] : {},
    }
  },
  methods:{
    getData(){
      this.loading = true
      // 优化效果
      this.labelList = [{
          category_id: '-1',
          id: '-1',
          label: '',
          isSelected: true
      }]
      queryAllFirstCategoryByShopId({ shopId: 'yingjing' }).then((res)=>{
        let resData = res.data.data.data
        this.firstCategoryLabelList = []
        resData.forEach((item, index) => {
          let temp = {}
          temp.id = item.first_category_id
          temp.name = item.first_category_name
          temp.img_url = item.img_url
          temp.mini_img_url = item.mini_img_url
          if(this.firstCategoryId){
            if(this.firstCategoryId === temp.id){
              temp.isSelected = true
            } else {
              temp.isSelected = false
            }
          } else {
            if(index === 0){
              temp.isSelected = true
            } else {
              temp.isSelected = false
            }
          }
          this.firstCategoryLabelList.push(temp)
        })
        let tempfirstCategoryId = this.firstCategoryId ? this.firstCategoryId : resData[0].first_category_id
        this.selectFirstCategoryId = tempfirstCategoryId
        this.getSecondCategory(tempfirstCategoryId)
      })
    },
    getSecondCategory(id){
      queryAllSecondCategoryById({ shopId: 'yingjing', firstCategoryId: id }).then((res)=>{
        let resData = res.data.data.data
        this.labelList = []
        resData.forEach((item, index)=>{
          let temp = {}
          temp.category_id = item.category_id
          temp.id = item.second_category_id
          temp.label = item.second_category_name
          if(index === 0){
            temp.isSelected = true
          } else {
            temp.isSelected = false
          }
          this.labelList.push(temp)
        })
        this.getCategoryGoods(id + '-' + this.labelList[0].id)
      })
    },
    goShopDescPage(id){
      this.$router.push({ path:'/shop-desc', query:{ gid: id } })
    },
    getCategoryGoods(id){
      this.loading = true
      querOnSaleCategoryGoodsByUid({ uid: '1000001', categoryId: id}).then((res)=>{
          let resData = res.data.data.data
          this.goodList = []
          resData.forEach(item=>{
            let temp = {}
            temp.id = item.gid
            temp.img = item.mini_main_list_img_url
            temp.title = item.title
            temp.price = item.price
            temp.amount = item.amount
            temp.type = item.color + ' | ' + item.type
            temp.category = item.category
            this.goodList.push(temp)
          })
          this.loading = false
        })
    },
    goSearch(){
      sessionStorage.setItem('fromPath','category')
      this.$router.push({ path:'/search', query:{ type:'goods' } })
    },
    addToCart(list){
      if(this.isLogin){
        let goodInfo = {}
        goodInfo.gid = list.id
        goodInfo.imgUrl = list.img
        goodInfo.title = list.title
        goodInfo.type = list.type
        goodInfo.amount = 1
        goodInfo.price = list.price
        goodInfo.uid = Number(JSON.parse(localStorage.getItem('userInfo'))[0].uid)
        goodInfo.shop = this.shopName
        createCart({ goodInfo: goodInfo }).then(()=>{
          this.initCartGoodsAmount()
          Toast('加入购物车成功')
        })
      } else {
        sessionStorage.setItem('fromPath', '/category')
        this.$router.push({ name:'Login', params:{ text:'选商品' } })
      }
    },
    changeFirstCategoryLabel(id){
      this.getSecondCategory(id)
      this.selectFirstCategoryId = id
      this.firstCategoryLabelList.forEach(label => {
        if (label.id === id) {
          label.isSelected = true;
        } else {
          label.isSelected = false;
        }
      });
    },
    changeLabel(id){
      this.getCategoryGoods(this.selectFirstCategoryId + '-' +id)
      this.labelList.forEach(label => {
        if (label.id === id) {
          label.isSelected = true;
        } else {
          label.isSelected = false;
        }
      });
    },
    initCartGoodsAmount(){
      if(this.isLogin){
          let uid = localStorage.getItem('userInfo') ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
          queryCartGoodsTotalAmount({ uid: uid }).then((res)=>{
            this.$store.commit('Set_CartAmount_State', res.data.total_amount)
          })
      } else {
        this.$store.commit('Set_CartAmount_State', 0)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.category-box {
    background: #f3f3f3;
    .search {
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      right: 0;
      background: #f3f3f3;
      display: flex;
      align-items: center;
      margin: 0 auto;
      max-width: 625px;
      .fake-input {
        width: calc(100% - 26px);
        border: 1px solid #eee;
        box-sizing: border-box;
        margin: 10px 5px 10px 10px;
        border-radius: 20px;
        height: 40px;
        line-height: 40px;
        display: flex;
        align-items: center;
        padding-left: 40px;
        color: #78788e;
        background: #fff;
      }
      .searchImg {
        position: absolute;
        left: 25px;
        top: 21px;
      }
    }
    .first-category-row {
      position: fixed;
      left: 0;
      right: 0;
      top: 60px;
      background: #f3f3f3;
      .first-category {
        margin: 0px 10px 0;
        display: flex;
        align-items: center;
        height: 80px;
        overflow-x: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+, edge */
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        .first-category-item {
          margin: 0 5px 0;
          .img-active {
            border: 2px solid #ffe8c0;
            // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
            color: #2c3e50;
          }
          .img {
            border: 2px solid rgba(0,0,0,0);
          }
          img {
            border-radius: 10px;
          }
          .label-active {
            font-weight: 600;
            font-size: 12px;
            display: flex;
            color: #2c3e50;
            // background: red;
            // padding: 2px 5px;
            // border-radius: 10px;
            justify-content: center;
            min-width: 50px;
          }
          .label {
            font-size: 12px;
            display: flex;
            // background: red;
            // padding: 2px 5px;
            // border-radius: 10px;
            justify-content: center;
            min-width: 50px;
          }
        }
      }
    }
    .sidebar {
      position: fixed;
      top: 140px;
      bottom: calc(55px + constant(safe-area-inset-bottom));
      bottom: calc(55px + env(safe-area-inset-bottom));
      background: rgb(243, 243, 243);
    }
    .category-card {
      margin: 140px 10px 55px 120px;
    }
}
</style>