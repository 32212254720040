<template>
    <div class="sidebar-box">
        <div v-for="list in labelList" :key="list.id" class="label" @click="handleClick(list.id)">
          <div v-if="list.isSelected" class="selected">{{ list.label }}</div>
          <div v-else class="default">{{ list.label }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name:'Sidebar',
    methods:{
      handleClick(id){
        this.$emit('changeLabel', id)
      }
    },
    data(){
        return {
        }
    },
    props: {
      labelList:{
        type: Array,
        default: ()=>[]
      },
    }
  };
</script>

<style lang="scss" scoped>
  .sidebar-box {
    position: absolute;
    left: 0;
    top: 0px;
    bottom: 0px;
    width: 120px;
    background: #fff;
    // border-right: 1px solid rgba(0,0,0,0.05);
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+, edge */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    overflow: scroll;
    .label {
      background: #f3f3f3;
      .selected {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 45px;
        background: #fff;
        color: #2c3e50;
      }
      .default {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 45px;
      }
    }
  }
</style>
  